
import { AxiosError } from "axios";
import { defineComponent, reactive, ref } from "vue";

import { useRouter } from "vue-router";

// services
import authService from "@/services/Auth";
import profileService from "@/services/profile.service";

import TemplatesLogin from "@/components/templates/login/index.vue";

import { IFormData } from "./interface";

export default defineComponent({
  components: { TemplatesLogin },
  setup() {
    const config = ref({
      isLoading: false,
      error: {},
    });
    const router = useRouter();

    const formData = reactive<IFormData>({
      username: "",
      password: "",
    });

    const getProfile = async () => {
      try {
        const { data } = await profileService.getProfileBParticipant();
        if (data && data.status === "Verified") router.push("/home");
        else router.push("/update-profile");
      } catch (e: unknown) {
        const { response } = e as AxiosError;

        alert(response?.data?.message);
      }
    };

    const submitLogin = async (formData: IFormData): Promise<void> => {
      config.value.isLoading = true;

      try {
        await authService.login(formData);
        await getProfile();
      } catch (e: unknown) {
        const { response } = e as AxiosError;

        if (Object.keys(response?.data?.errors).length > 0) {
          config.value.error = response?.data?.errors;
        } else {
          alert(response?.data?.message);
        }
      }

      config.value.isLoading = false;
    };

    return {
      config,
      formData,
      submitLogin,
    };
  },
});
