import httpClient from "@/services/http-client";

import { IPayloadLogin, IPayloadLoginAdmin } from "./interface";

const login = async (formData: IPayloadLogin): Promise<void> => {
  const {
    data: { data },
  } = await httpClient.post("/b-participant/v1/auth/login", formData);

  localStorage.setItem("authData", JSON.stringify(data));
  return data;
};

const loginAdmin = async (formData: IPayloadLoginAdmin): Promise<void> => {
  const {
    data: { data },
  } = await httpClient.post("b-client/v1/auth/login", formData);

  data.isAdmin = true;
  localStorage.setItem("authData", JSON.stringify(data));
  return data;
};

export default {
  login,
  loginAdmin,
};
